import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import userImg from "assets/img/user.jpeg";
import bronze from "assets/images/ranks/bronze.png";
import silver from "assets/images/ranks/silver.png";
import gold from "assets/images/ranks/gold.png";
import BidModal from "components/BidModal";
import Checkout from "components/Checkout";
import ImageGallery from "components/ImageGallery";
import MainCalendar from "components/googleCalendar/MainCalendar";
import Calendar from "components/googleCalendar/Calendar";
import Loader from "components/Loader";
import { useGetUserProfileQuery } from "api/homeApi";
import { setLoginModalVisibility } from "slices/globalSlice";
import { toast } from "react-toastify";
import SessionForm from "components/Session/Form";
import About from "components/Profile/About";
import ActionButtons from "components/Profile/ActionButtons";
import SessionDetail from "components/Session/Detail";
import ClipsGallery from "components/ClipsGallery";

const Profile = () => {
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState(0);
  const [user, setUser] = useState({});
  const [sessions, setSessions] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [clips, setClips] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [bookedSession, setBookedSession] = useState([]);
  const [showBidModal, setShowBidModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showAddSessionBtn, setAddSessionBtn] = useState(false);
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(null);
  const [sessionFormData, setSessionFormData] = useState(null);
  const [showSessionDetailModal, setShowSessionDetailModal] = useState(false);
  const { isAuthenticated, user: loggedInUser } = useSelector(
    (state) => state.auth
  );

  const { profileId } = useParams();
  const {
    data,
    error,
    isLoading,
    refetch: refetchUser,
  } = useGetUserProfileQuery(profileId);

  useEffect(() => {
    if (data) {
      setUser(data.user);
      setSessions(data.sessions);
      setBookedSession(data.participated_sessions);
      setAddSessionBtn(data.user?.email === loggedInUser?.email);
      setPhotos(data.photos);
      setClips(data.clips);
    }
  }, [data]);

  useEffect(() => {
    refetchUser();
  }, []);

  const isCurrentUser = () => {
    if (isAuthenticated) {
      return true;
    }
    dispatch(setLoginModalVisibility(true));
    return false;
  };

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? 0 : index);
  };

  const handleModalClose = () => {
    setShowBidModal(false);
  };

  const getRankImage = (rank) => {
    if (rank === "bronze") {
      return bronze;
    } else if (rank === "silver") {
      return silver;
    } else if (rank === "gold") {
      return gold;
    }
  };

  const handleEventClick = (isBooked, session) => {
    if (!isCurrentUser()) return;

    if (isBooked) {
      return toast.info("You are already registered for this session.", {
        position: "bottom-right",
      });
    }

    if (session.reserved_slots === session.max_users) {
      return toast.info(
        "The session is fully booked, and no slots are available.",
        {
          position: "bottom-right",
        }
      );
    }

    setSelectedSession(session);
    setShowSessionDetailModal(true);
    // setShowCheckoutModal(true);
  };

  const handleDayClick = (currentDate, day) => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    setSelectedCalendarDate({
      year,
      month,
      day,
    });
  };

  if (isLoading) {
    return <Loader isLoading={true} />;
  }
  if (error) {
    return <p>User Profile not found</p>;
  }

  return (
    user && (
      <div className="header__wrapper">
        <header
          style={{
            backgroundImage: `url("${
              user.cover_photo_url ||
              "https://i.pinimg.com/736x/a9/4c/97/a94c9773b740c9c6242cb4a5f108fc2b.jpg"
            }")`,
          }}
        ></header>
        <div className="cols__container">
          <div className="left__col">
            <div className="img__container user_profile_img">
              <img
                src={user.profile_picture_url || userImg}
                alt={user.username}
              />
              <span></span>
            </div>
            <div className="about-section">
              <div>
                <h2>{user.first_name + " " + user.last_name}</h2>
                <p>@{user.username}</p>
                {/* <p>Streamer</p> */}
              </div>
              <ActionButtons
                isFollowed={data.is_following}
                isFriend={data.is_friend}
                pendingSent={data.pending_sent}
                pendingReceived={data.pending_received}
                isCurrentUser={isCurrentUser}
                profileId={profileId}
              />
              <ul className="popularity">
                <li>
                  Followers<span>{user.followers_count}</span>
                </li>
                <li>
                  Following <span>{user.followings_count}</span>
                </li>
                <li className="rank__container">
                  <span>
                    <img src={getRankImage(user.rank || "bronze")} alt="rank" />
                  </span>
                </li>
              </ul>
              <Calendar handleDayClick={handleDayClick} />
            </div>
          </div>
          <div className="right__col">
            <nav>
              <ul>
                <li
                  className={activeSection === 0 ? "active" : ""}
                  onClick={() => handleSectionClick(0)}
                >
                  <p>Book Session</p>
                </li>
                <li
                  className={activeSection === 1 ? "active" : ""}
                  onClick={() => handleSectionClick(1)}
                >
                  <p>About</p>
                </li>
                <li
                  className={activeSection === 2 ? "active" : ""}
                  onClick={() => handleSectionClick(2)}
                >
                  <p>Photos</p>
                </li>
                <li
                  className={activeSection === 3 ? "active" : ""}
                  onClick={() => handleSectionClick(3)}
                >
                  <p>Clips</p>
                </li>
              </ul>
            </nav>

            {activeSection === 0 && (
              <MainCalendar
                sessions={sessions}
                bookedSession={bookedSession}
                handleEventClick={handleEventClick}
                showAddSessionBtn={showAddSessionBtn}
                setShowSessionModal={() =>
                  setShowSessionModal(!showSessionModal)
                }
                selectedCalendarDate={selectedCalendarDate}
                setSessionFormData={setSessionFormData}
                isAuthenticated={isAuthenticated}
              />
            )}
            {activeSection === 1 && <About user={user} />}
            {activeSection === 2 && <ImageGallery photos={photos} />}
            {activeSection === 3 && <ClipsGallery clips={clips} />}
          </div>
        </div>
        <BidModal show={showBidModal} handleClose={handleModalClose} />
        {showCheckoutModal && (
          <Checkout
            show={showCheckoutModal}
            selectedSession={selectedSession}
            refetchUser={refetchUser}
            handleClose={() => setShowCheckoutModal(false)}
          />
        )}
        {showSessionModal && (
          <SessionForm
            show={showSessionModal}
            handleClose={() => setShowSessionModal(false)}
            refetchSessions={refetchUser}
            selectedSession={null}
            formData={sessionFormData}
          />
        )}
        {showSessionDetailModal && (
          <SessionDetail
            session={selectedSession}
            visible={showSessionDetailModal}
            setShowCheckoutModal={setShowCheckoutModal}
            onClose={() => setShowSessionDetailModal(false)}
          />
        )}
      </div>
    )
  );
};

export default Profile;
