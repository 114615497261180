import React, { useState } from "react";
import {
  useSendRequestMutation,
  useAcceptRequestMutation,
  useRejectRequestMutation,
  useRemoveFriendMutation,
  useFollowUserMutation,
  useUnfollowUserMutation,
} from "api/homeApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faCheck,
  faTimes,
  faUserPlus,
  faUserTimes,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faRegularHeart } from "@fortawesome/free-regular-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

const ActionButtons = ({
  isFollowed,
  isFriend,
  pendingSent,
  pendingReceived,
  isCurrentUser,
  profileId,
}) => {
  const [followed, setFollowed] = useState(isFollowed);
  const [friends, setFriends] = useState(isFriend);
  const [requested, setRequested] = useState(pendingSent);
  const [requestRecived, setRequestReceived] = useState(pendingReceived);
  const [sendRequest] = useSendRequestMutation();
  const [acceptRequest] = useAcceptRequestMutation();
  const [rejectRequest] = useRejectRequestMutation();
  const [removeFriend] = useRemoveFriendMutation();

  const [followUser] = useFollowUserMutation();
  const [unfollowUser] = useUnfollowUserMutation();

  const handleFollowToggle = async () => {
    if (!isCurrentUser()) return;

    const action = followed ? unfollowUser : followUser;
    const successMessage = followed
      ? "You unfollowed this user"
      : "You are now following this user";
    const failureMessage = followed
      ? "Failed to unfollow user"
      : "Failed to follow user";

    try {
      await action(profileId).unwrap();
      setFollowed(!followed);
      handleToaster(successMessage, "success");
    } catch (err) {
      handleToaster(failureMessage);
    }
  };

  const handleSendRequest = async () => {
    if (!isCurrentUser()) return;

    try {
      await sendRequest(profileId);
      setRequested(true);
      handleToaster("Friend request sent!", "success");
    } catch (error) {
      handleToaster("Failed to send friend request.");
      console.error(error);
    }
  };

  const handleRemoveFriend = async () => {
    if (!isCurrentUser()) return;

    try {
      await removeFriend(profileId);
      setFriends(false);
      setRequested(false);
      handleToaster("Friend removed!", "success");
    } catch (error) {
      handleToaster("Failed to remove friend.");
      console.error(error);
    }
  };

  const handleAcceptRequest = async () => {
    if (!isCurrentUser()) return;

    try {
      await acceptRequest(profileId);
      setRequestReceived(false);
      setFriends(true);
      handleToaster("Friend request accepted!", "success");
    } catch (error) {
      handleToaster("Failed to accept friend request.");
      console.error(error);
    }
  };

  const handleRejectRequest = async () => {
    if (!isCurrentUser()) return;

    try {
      await rejectRequest(profileId);
      setRequestReceived(false);
      setFriends(false);
      handleToaster("Friend request rejected.", "success");
    } catch (error) {
      handleToaster("Failed to reject friend request.");
      console.error(error);
    }
  };

  const handleToaster = (message, type = "error") => {
    if (type === "success") {
      toast.success(message, { position: "bottom-right" });
    } else {
      toast.error(message, {
        position: "bottom-right",
      });
    }
  };

  return (
    <div className="action-buttons">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{followed ? "Unfollow" : "Follow"}</Tooltip>}
      >
        <button className="btn" onClick={handleFollowToggle}>
          <FontAwesomeIcon
            icon={followed ? faHeart : faRegularHeart}
            size="lg"
          />
        </button>
      </OverlayTrigger>

      {requestRecived ? (
        <>
          <OverlayTrigger placement="top" overlay={<Tooltip>Accept</Tooltip>}>
            <button className="btn" onClick={handleAcceptRequest}>
              <FontAwesomeIcon icon={faCheck} size="lg" />
            </button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip>Reject</Tooltip>}>
            <button className="btn" onClick={handleRejectRequest}>
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
          </OverlayTrigger>
        </>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={
            friends ? (
              <Tooltip>Remove Friend</Tooltip>
            ) : requested ? (
              <Tooltip>Remove Request</Tooltip>
            ) : (
              <Tooltip>Add Friend</Tooltip>
            )
          }
        >
          <button
            className="btn"
            onClick={
              friends
                ? handleRemoveFriend
                : requested
                ? handleRemoveFriend
                : handleSendRequest
            }
          >
            <FontAwesomeIcon
              icon={
                friends ? faUserTimes : requested ? faUserTimes : faUserPlus
              }
              size="lg"
            />
          </button>
        </OverlayTrigger>
      )}

      <OverlayTrigger placement="top" overlay={<Tooltip>Message</Tooltip>}>
        <button className="btn">
          <FontAwesomeIcon icon={faComment} size="lg" />
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default ActionButtons;
