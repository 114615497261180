import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebookF,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const UserTile = ({ item, index }) => {
  const navigate = useNavigate();
  const fullName = item.first_name + " " + item.last_name;

  const handleClick = () => {
    navigate(`/profile/${item.id}`);
  };

  const handleSocialClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="card__glass"
      key={index}
      style={{
        backgroundImage: `url(${item.profile_picture_url})`,
      }}
      onClick={handleClick}
    >
      <div className="card__data">
        <h3 className="card__title">{fullName}</h3>
        <span className="card__profession">{item.username}</span>
        <Link to={`/profile/${item.id}`}>
          <FontAwesomeIcon icon={faEye} inverse />
        </Link>
      </div>

      <div className="card__social">
        <a
          href={item.instagram_url}
          target="_blank"
          className="card__link"
          onClick={handleSocialClick}
        >
          <FontAwesomeIcon icon={faInstagram} inverse />
        </a>
        <a
          href={item.facebook_url}
          target="_blank"
          className="card__link"
          onClick={handleSocialClick}
        >
          <FontAwesomeIcon icon={faFacebookF} inverse />
        </a>
        <a
          href={item.tiktok_url}
          target="_blank"
          className="card__link"
          onClick={handleSocialClick}
        >
          <FontAwesomeIcon icon={faTiktok} inverse />
        </a>
        <a
          href={item.twitter_url}
          target="_blank"
          className="card__link"
          onClick={handleSocialClick}
        >
          <FontAwesomeIcon icon={faXTwitter} inverse />
        </a>
        <a
          href={item.youtube_url}
          target="_blank"
          className="card__link"
          onClick={handleSocialClick}
        >
          <FontAwesomeIcon icon={faYoutube} inverse />
        </a>
      </div>
      <div className="card__overlay"></div>
    </div>
  );
};

export default UserTile;
