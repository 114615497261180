import React from "react";
import { useBecomeHostMutation } from "api/profileApi";
import { Button } from "antd";
import { toast } from "react-toastify";
import "./styles.scss";

const ProfileHeader = ({ profileData }) => {
  const [becomeHost, { isLoading }] = useBecomeHostMutation();

  const handleBecomeHost = async () => {
    try {
      const res = await becomeHost(profileData.id);
      toast.success(
        "Please check your email and complete your Stripe account setup.",
        { position: "bottom-right" }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="profile-header">
      <header
        style={{
          backgroundImage: `url("${
            profileData.cover_photo_url ||
            "https://i.pinimg.com/736x/a9/4c/97/a94c9773b740c9c6242cb4a5f108fc2b.jpg"
          }")`,
        }}
      ></header>
      <div className="profileDetails">
        <div className="img__container">
          <img src={profileData.profile_picture_url} alt="User" />
        </div>
        <div className="about-section">
          <div>
            <h2>{profileData.first_name + " " + profileData.last_name}</h2>
            <div className="profile-progress-bar">
              <p>Complete your profile</p>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${profileData.profile_completion_percentage}%`,
                  }}
                  aria-valuenow={`${profileData.profile_completion_percentage}`}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span>{profileData.profile_completion_percentage}%</span>
                </div>
              </div>
            </div>
            {profileData.profile_completion_percentage === 100 && (
              <Button
                onClick={handleBecomeHost}
                className="become-host-btn mt-2"
              >
                {isLoading ? "Loading...." : "Become Host"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
