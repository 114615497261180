import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = window.localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      if (endpoint !== "updateProfile") {
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProfileById: builder.query({
      query: (id) => `/profiles/${id}/edit`,
    }),
    getFollowingsAndAppointments: builder.query({
      query: (id) => `/profiles/${id}/following`,
    }),
    getFriendsAndAppointments: builder.query({
      query: (id) => `/profiles/${id}/friends`,
    }),
    updateProfile: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/profiles/${id}`,
        method: "PATCH",
        body: formData,
        formData: true,
      }),
    }),
    becomeHost: builder.mutation({
      query: ({ id }) => ({
        url: `/profiles/${id}/become_host`,
        method: "POST",
      }),
    }),
    updatePassword: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/profiles/${id}/update_password`,
        method: "PUT",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetProfileByIdQuery,
  useGetFollowingsAndAppointmentsQuery,
  useGetFriendsAndAppointmentsQuery,
  useUpdateProfileMutation,
  useBecomeHostMutation,
  useUpdatePasswordMutation,
} = profileApi;
