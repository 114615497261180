import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useLoginMutation } from "../api/authApi";
import { setAuth } from "../slices/authSlice";
import { toast } from "react-toastify";

const Login = ({ show, handleModalHide, handleModalShow }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    if (!show) {
      setErrors({});
      setEmail("");
      setPassword("");
      setShowPassword(false);
    }
  }, [show]);

  const validateForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required";
    if (!password) newErrors.password = "Password is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await login({ user: { email, password } }).unwrap();
      if (response.status === "success") {
        dispatch(
          setAuth({
            user: response.user,
            token: response.token,
          })
        );
        window.localStorage.setItem("access_token", response.token);
        handleModalHide("login");
        toast.success(response.message, { position: "bottom-right" });
      } else {
        const errMsg = response.error.data;
        toast.error(errMsg || "Login failed. Please try again.", {
          position: "bottom-right",
        });
      }
    } catch (err) {
      toast.error("Login failed. Please try again.", {
        position: "bottom-right",
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => handleModalHide("login")}
      className="login-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-container">
        <h1 className="login-title">Welcome To Gameo</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="input-group">
            <div className="input-with-icon mt-2">
              <input
                className="form-control"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FontAwesomeIcon className="icon-envelope" icon={faEnvelope} />
            </div>
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div className="input-group">
            <div className="input-with-icon mt-2">
              <input
                className="form-control"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword ? (
                <FontAwesomeIcon
                  className="icon-eye"
                  icon={faEyeSlash}
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <FontAwesomeIcon
                  className="icon-eye"
                  icon={faEye}
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
            {errors.password && (
              <p className="error-message">{errors.password}</p>
            )}
          </div>
          <div className="remember-me">
            <input type="checkbox" id="rememberMe" />
            <label htmlFor="rememberMe">Remember Me</label>
          </div>
          <button type="submit" className="login-btn" disabled={isLoading}>
            {isLoading ? "Logging in..." : "Login"}
          </button>
          <div className="login-options">
            <a
              className="forgot-password"
              onClick={() => handleModalShow("forgot-password")}
            >
              Forget password
            </a>
            <a
              className="create-account"
              onClick={() => handleModalShow("register")}
            >
              Create New Account
            </a>
          </div>
          <button className="guest-login-btn">Continue as guest</button>
        </form>
      </div>
    </Modal>
  );
};

export default Login;
