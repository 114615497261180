import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetHomeDataQuery } from "api/homeApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEnvelope,
  faLocationDot,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import UserTile from "components/UserTile";
import Loader from "components/Loader";
import CustomSlider from "components/customSlider";
import fb from "assets/images/icons/fb.png";
import insta from "assets/images/icons/insta.png";
import linkedin from "assets/images/icons/linkedin.png";
import twitter from "assets/images/icons/twitter.png";
import Logo from "assets/images/logo.png";

const Home = () => {
  const { data, isLoading, refetch } = useGetHomeDataQuery();
  const [trendingGames, setTrendingGames] = useState([]);
  const [newGames, setNewGames] = useState([]);
  const [trendingUsers, setTrendingUsers] = useState([]);
  const [friends, setFriends] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    refetch();
  }, [isAuthenticated]);

  useEffect(() => {
    if (data) {
      const {
        trending_games,
        new_games,
        trending_users,
        new_users,
        categories,
        friends,
      } = data;
      setTrendingGames(trending_games);
      setNewGames(new_games);
      setTrendingUsers(trending_users);
      setNewUsers(new_users);
      setCategories(categories);
      setFriends(friends);
    }
  }, [data]);

  const renderContent = (title, data) => {
    return (
      <div
        className={`content-container my-5 ${
          title === "TrendinstreamingUsersg" ? "trending" : ""
        }`}
      >
        <h1 className="content-title">{title}</h1>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : (
          <div className="card__container bd-container">
            {data.map((item, index) => (
              <UserTile item={item} index={index} />
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderExtraContent = (title, data) => {
    return (
      <div className="content-container my-5">
        <h1 className="content-title">{title}</h1>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : (
          <div className="card__container bd-container">
            {data.map((item, index) => (
              <div
                className="card__glass"
                key={index}
                style={{
                  backgroundImage: `url(${item.image_url})`,
                }}
              >
                <div className="card__data">
                  <h3 className="card__title">{item.title}</h3>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const usersToDisplay = isAuthenticated ? friends : trendingUsers;

  return (
    <section className="content">
      <div className="left-content">
        <CustomSlider />

        {renderContent("Trending", trendingUsers)}
        {renderContent("Popular", trendingUsers)}
        {renderContent("Popular This Week", trendingUsers)}
        {renderContent("Popular This Month", trendingUsers)}
        {renderContent("Most Transactions", trendingUsers)}
        {renderContent("New", newUsers)}
        {renderExtraContent("Recommended Games", trendingGames)}
        {renderExtraContent("New Games", newGames)}

        <div className="footer">
          <div className="brand-con">
            <img className="brand-logo" src={Logo} alt="logo" />
          </div>
          <div className="social-links">
            <div>
              <img src={fb} alt="fb" />
            </div>
            <div>
              <img src={insta} alt="insta" />
            </div>
            <div>
              <img src={linkedin} alt="linkedin" />
            </div>
            <div>
              <img src={twitter} alt="twitter" />
            </div>
          </div>
          <div className="contact-info">
            <div className="email">
              <FontAwesomeIcon icon={faEnvelope} inverse />
              <span>info@gameo.co</span>
            </div>
            <div className="location">
              <FontAwesomeIcon icon={faLocationDot} inverse />
              <span>United State</span>
            </div>
          </div>
          <p className="copyright">
            Copyrights © 2024 Gameo. All Rights Reserved.
          </p>
        </div>
      </div>

      <div className="right-content">
        <div className="recommended-users">
          <h1>{isAuthenticated ? "Friends" : "Trendings"}</h1>
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <div className="user-container">
              {usersToDisplay.length === 0 ? (
                <div className="no-records">
                  <p>No users to display.</p>
                </div>
              ) : (
                usersToDisplay.map((item, index) => (
                  <div className="user-info" key={index}>
                    <div className="user-img">
                      <img src={item.profile_picture_url} alt="" />
                      <Link
                        className="overlay"
                        to={`/profile/${item.username}`}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Link>
                    </div>
                    <div className="user-title">
                      <h2>{item.first_name + " " + item.last_name}</h2>
                      <p>{item.username}</p>
                      <span>{item.followers_count} Followers</span>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
        <div className="right-side">
          <h1>Get In Touch</h1>
          <p>
            Please provide the details below to be the first to know about the
            news Gameo
          </p>
          <form>
            <div className="form-group">
              <label>Name*</label>
              <input
                type="text"
                id="fullname"
                name="fullname"
                placeholder="Enter Full Name"
              />
            </div>
            <div className="form-group">
              <label>Email*</label>
              <input
                type="text"
                id="fullname"
                name="fullname"
                placeholder="Enter Email Address"
              />
            </div>
            <button type="submit" className="btn submit-btn">
              <span>Submit</span>
              <FontAwesomeIcon icon={faPaperPlane} size="sm" />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Home;
