import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import deadRising from "../assets/images/dead-rising-remaster-frank-west-motorcycle.jpg";
import deadlock from "../assets/images/deadlock.jpg";
import eaSports from "../assets/images/ea-sports-fc-25.jpg";
import ghostOfYotei from "../assets/images/ghost-of-yotei.jpg";
import harryPotter from "../assets/images/harry-potter.jpg";

const CustomSlider = () => {
  const swiperRef = useRef(null);

  return (
    <div className="slider-container">
      <h1 className="slider-title">
        Play, Compete, Connect with Gameo’s Global Community!
      </h1>
      <Swiper
        ref={swiperRef}
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        speed={600}
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 10,
          stretch: 120,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
        }}
        onClick={() => {
          const swiper = swiperRef.current.swiper;
          if (swiper.clickedIndex !== undefined) {
            swiper.slideTo(swiper.clickedIndex);
          }
        }}
        modules={[EffectCoverflow, Pagination]}
      >
        <div className="swiper-wrapper">
          <SwiperSlide>
            <img src={deadRising} alt="Dead Rising" />
            <div className="slide-overlay">
              <h2>Dead Rising Remaster</h2>
              <button>
                Watch <FontAwesomeIcon icon={faCirclePlay} />
              </button>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <img src={deadlock} alt="Deadlock" />
            <div className="slide-overlay">
              <h2>Deadlock</h2>
              <button>
                Watch <FontAwesomeIcon icon={faCirclePlay} />
              </button>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <img src={eaSports} alt="EA Sports FC 25" />
            <div className="slide-overlay">
              <h2>EA Sports FC 25</h2>
              <button>
                Watch <FontAwesomeIcon icon={faCirclePlay} />
              </button>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <img src={ghostOfYotei} alt="Ghost of Yotei" />
            <div className="slide-overlay">
              <h2>Ghost of Yotei</h2>
              <button>
                Watch <FontAwesomeIcon icon={faCirclePlay} />
              </button>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <img src={harryPotter} alt="Harry Potter" />
            <div className="slide-overlay">
              <h2>Harry Potter</h2>
              <button>
                Watch <FontAwesomeIcon icon={faCirclePlay} />
              </button>
            </div>
          </SwiperSlide>
        </div>
        <div className="swiper-pagination"></div>
      </Swiper>
    </div>
  );
};

export default CustomSlider;
