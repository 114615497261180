import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  InputNumber,
  Select,
  DatePicker,
  TimePicker,
  Input,
} from "antd";
import {
  useCreateSessionMutation,
  useUpdateSessionMutation,
} from "api/sessionApi";
import { useFetchHostsQuery } from "api/homeApi";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "./styles.scss";

const SessionForm = ({
  show,
  handleClose,
  refetchSessions,
  selectedSession,
  formData = null,
}) => {
  const [createSession, { isLoading }] = useCreateSessionMutation();
  const [updateSession, { isUpdating }] = useUpdateSessionMutation();
  const { data: hosts = [] } = useFetchHostsQuery();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    id: "",
    session_date: "",
    start_time: "",
    end_time: "",
    price: 0,
    co_hosts: [],
    title: "",
    description: "",
    max_users: 1,
    status: "pending",
  });

  useEffect(() => {
    if (selectedSession) {
      setFormValues({
        id: selectedSession.id || "",
        session_date: selectedSession.session_date || "",
        start_time: selectedSession.start_time || "",
        end_time: selectedSession.end_time || "",
        price: selectedSession.price || 0,
        co_hosts: selectedSession.co_hosts || [],
        title: selectedSession.title || "",
        description: selectedSession.description || "",
        max_users: selectedSession.max_users || 1,
        status: selectedSession.status || "pending",
      });
      form.setFieldsValue({
        title: selectedSession.title,
        description: selectedSession.description,
        session_date: selectedSession.session_date
          ? dayjs(selectedSession.session_date, "YYYY-MM-DD")
          : null,
        start_time: selectedSession.start_time
          ? dayjs(selectedSession.start_time, "HH:mm")
          : null,
        end_time: selectedSession.end_time
          ? dayjs(selectedSession.end_time, "HH:mm")
          : null,
        price: selectedSession.price,
        max_users: selectedSession.max_users,
        co_hosts: selectedSession.co_hosts || [],
      });
    }
    if (formData) {
      setFormValues((prev) => ({
        ...prev,
        session_date: formData.date || "",
        start_time: formData.startTime || "",
        end_time: formData.endTime || "",
      }));
      form.setFieldsValue({
        session_date: formData.date ? dayjs(formData.date, "YYYY-MM-DD") : null,
        start_time: formData.startTime
          ? dayjs(formData.startTime, "HH:mm")
          : null,
        end_time: formData.endTime ? dayjs(formData.endTime, "HH:mm") : null,
      });
    }
  }, [selectedSession, form, formData]);

  const handleSelectChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      co_hosts: value,
    }));
  };

  const handleDateChange = (date, dateString) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      session_date: dateString,
    }));
  };

  const handleTimeChange = (time, timeString, inputId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [inputId]: timeString,
    }));
  };

  const handleSubmit = async (values) => {
    try {
      const isUpdate = Boolean(selectedSession);
      const sessionAction = isUpdate ? updateSession : createSession;
      const successMessage = isUpdate
        ? "Successfully updated the session"
        : "Successfully added the session";

      await sessionAction(formValues).unwrap();
      toast.success(successMessage, {
        position: "bottom-right",
      });
      handleClose();
      refetchSessions();
    } catch (error) {
      toast.error("Something went wrong. Try Again", {
        position: "bottom-right",
      });
      console.error(error);
    }
  };

  return (
    <Modal
      title="New Session"
      open={show}
      onCancel={handleClose}
      footer={null}
      centered
      className={"session-form"}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input a title!" }]}
        >
          <Input
            value={formValues.title}
            onChange={(e) =>
              setFormValues((prev) => ({ ...prev, title: e.target.value }))
            }
            className="custom-input"
          />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea
            value={formValues.description}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
            className="custom-input"
            rows={4}
          />
        </Form.Item>

        <Form.Item
          label="Select Date"
          name="session_date"
          rules={[{ required: true, message: "Please select a date!" }]}
        >
          <DatePicker
            value={
              formValues.session_date
                ? dayjs(formValues.session_date, "YYYY-MM-DD")
                : null
            }
            format={"YYYY-MM-DD"}
            onChange={handleDateChange}
            className="custom-input"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Start Time"
          name="start_time"
          rules={[{ required: true, message: "Please select start time!" }]}
        >
          <TimePicker
            value={
              formValues.start_time
                ? dayjs(formValues.start_time, "HH:mm")
                : null
            }
            onChange={(time, timeString) =>
              handleTimeChange(time, timeString, "start_time")
            }
            className={"custom-input time-picker"}
            format="HH:mm"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="End Time"
          name="end_time"
          rules={[{ required: true, message: "Please select end time!" }]}
        >
          <TimePicker
            value={
              formValues.end_time ? dayjs(formValues.end_time, "HH:mm") : null
            }
            onChange={(time, timeString) =>
              handleTimeChange(time, timeString, "end_time")
            }
            className={"custom-input time-picker"}
            format="HH:mm"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Please input a price!" }]}
        >
          <InputNumber
            value={formValues.price}
            onChange={(value) =>
              setFormValues((prev) => ({ ...prev, price: value }))
            }
            min={0}
            step={0.01}
            className="custom-input"
            style={{ width: "100%" }}
            prefix="$"
          />
        </Form.Item>

        <Form.Item
          label="Max Users"
          name="max_users"
          rules={[{ required: true, message: "Please input max users!" }]}
        >
          <InputNumber
            value={formValues.max_users}
            onChange={(value) =>
              setFormValues((prev) => ({ ...prev, max_users: value }))
            }
            min={1}
            className="custom-input"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item label="Co-Hosts" name="co_hosts">
          <Select
            mode="multiple"
            showSearch
            style={{ width: "100%" }}
            placeholder="Select co-hosts"
            onChange={handleSelectChange}
            value={formValues.co_hosts}
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            className="custom-input"
            options={(hosts || []).map((user) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
            }))}
          />
        </Form.Item>

        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn submit-btn"
            disabled={isLoading || isUpdating}
          >
            Save
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default SessionForm;
