import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => "/notifications",
    }),
    markAsRead: builder.mutation({
      query: () => ({
        url: `/notifications/mark_as_read`,
        method: "PUT",
      }),
    }),
  }),
});

export const { useGetNotificationsQuery, useMarkAsReadMutation } =
  notificationsApi;
