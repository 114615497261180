import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = window.localStorage.getItem("access_token");
      if (token && endpoint !== "register") {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation({
      query: (userData) => ({
        url: "signup",
        method: "POST",
        body: userData,
        formData: true,
      }),
    }),
    verifyUser: builder.query({
      query: () => "is_authenticated",
    }),
    logOut: builder.mutation({
      query: () => ({
        url: "logout",
        method: "DELETE",
      }),
    }),
    resetPassword: builder.mutation({
      query: (resetPassPayload) => ({
        url: "password",
        method: "PUT",
        body: resetPassPayload,
      }),
    }),
    resetPasswordReq: builder.mutation({
      query: (data) => ({
        url: "password",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyUserQuery,
  useLogOutMutation,
  useResetPasswordMutation,
  useResetPasswordReqMutation,
} = authApi;
