import React from "react";
import { useSelector } from "react-redux";
import { Form, Input, Button } from "antd";
import { useUpdatePasswordMutation } from "api/profileApi";
import { toast } from "react-toastify";
import "./styles.scss";

const PasswordForm = () => {
  const { id } = useSelector((state) => state.auth.user);
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const res = await updatePassword({ id, payload: values });
      if (res.error) {
        toast.error(res.error.data.error, { position: "bottom-right" });
        return;
      }
      form.resetFields();
      toast.success("Password updated successfully!", {
        position: "bottom-right",
      });
    } catch (error) {
      console.log("error", error);
      toast.error("Try Again", { position: "bottom-right" });
    }
  };

  return (
    <div className="content-body password-container">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <h2>Password</h2>
        <Form.Item
          name="current_password"
          label={<span>Current Password</span>}
          rules={[
            { required: true, message: "Please enter your current password!" },
          ]}
        >
          <Input.Password placeholder="Current Password" />
        </Form.Item>

        <Form.Item
          name="password"
          label={<span>New Password</span>}
          rules={[
            { required: true, message: "Please enter your new password!" },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
              message:
                "Password must include both letters and numbers and be at least 8 characters long.",
            },
          ]}
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>

        <Form.Item
          name="password_confirmation"
          label={<span>Confirm Password</span>}
          dependencies={["password"]}
          rules={[
            { required: true, message: "Please confirm your password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match!"));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Update Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordForm;
