import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const sessionParticipantsApi = createApi({
  reducerPath: "sessionParticipantsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getParticipants: builder.query({
      query: () => "/session_participants",
    }),
  }),
});

export const { useGetParticipantsQuery } = sessionParticipantsApi;
