import React from "react";

const ImageGallery = ({ photos }) => {
  return (
    <div className="gallery my-3">
      {photos.length === 0 ? (
        <p className="text-center text-white">No photos available</p>
      ) : (
        photos.map((src, index) => (
          <div
            key={index}
            className={`image-container ${index % 7 === 0 ? "tall" : ""}`}
          >
            <img src={src} alt={`Gallery Image ${index + 1}`} />
          </div>
        ))
      )}
    </div>
  );
};

export default ImageGallery;
