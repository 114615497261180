import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookF,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const About = ({ user }) => {
  return (
    <div className="user-content">
      <p dangerouslySetInnerHTML={{ __html: user.bio }} />
      <ul>
        <li>
          <a href={user.instagram_url}>
            <FontAwesomeIcon icon={faInstagram} inverse />
          </a>
        </li>
        <li>
          <a href={user.facebook_url}>
            <FontAwesomeIcon icon={faFacebookF} inverse />
          </a>
        </li>
        <li>
          <a href={user.tiktok_url}>
            <FontAwesomeIcon icon={faTiktok} inverse />
          </a>
        </li>
        <li>
          <a href={user.twitter_url}>
            <FontAwesomeIcon icon={faXTwitter} inverse />
          </a>
        </li>
        <li>
          <a href={user.youtube_url}>
            <FontAwesomeIcon icon={faYoutube} inverse />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default About;
