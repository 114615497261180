import React, { useEffect, useState } from "react";
import { Input, Button, Row, Col, Dropdown, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faEllipsis,
  faPaperclip,
  faPaperPlane,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import jerry from "assets/images/jerry.png";
import "./styles.scss";

const { TextArea } = Input;

const userLists = {
  friends: [
    {
      img_url: jerry,
      name: "Sarah Davis",
      message: "Where are you now?",
    },
    {
      img_url: jerry,
      name: "John Doe",
      message: "Hello, how are you?",
    },
    {
      img_url: jerry,
      name: "Sarah Davis",
      message: "I’m running late, sorry!",
    },
    {
      img_url: jerry,
      name: "David Wilson",
      message: "Let’s catch up soon!",
    },
    {
      img_url: jerry,
      name: "Olivia Lee",
      message: "I’m at the coffee shop now.",
    },
    {
      img_url: jerry,
      name: "Olivia Lee",
      message: "Good morning!",
    },
    {
      img_url: jerry,
      name: "Mason Lewis",
      message: "I’ll be there in 10 minutes.",
    },
    {
      img_url: jerry,
      name: "Emily Johnson",
      message: "I’m running late, sorry!",
    },
    {
      img_url: jerry,
      name: "Michael Brown",
      message: "I’m at the coffee shop now.",
    },
    {
      img_url: jerry,
      name: "Daniel Thomas",
      message: "Thanks for your help!",
    },
    {
      img_url: jerry,
      name: "James Anderson",
      message: "Let’s catch up soon!",
    },
    {
      img_url: jerry,
      name: "Daniel Thomas",
      message: "Thanks for your help!",
    },
    {
      img_url: jerry,
      name: "Olivia Lee",
      message: "I’ll be there in 10 minutes.",
    },
    {
      img_url: jerry,
      name: "Ella Harris",
      message: "See you soon!",
    },
  ],
  followers: [
    {
      img_url: jerry,
      name: "Sophia Green",
      message: "It was great meeting you!",
    },
    {
      img_url: jerry,
      name: "Liam Martinez",
      message: "Hope to see you again soon!",
    },
    {
      img_url: jerry,
      name: "Amelia White",
      message: "Can you share the details, please?",
    },
    {
      img_url: jerry,
      name: "Noah Taylor",
      message: "Let’s work on the project tomorrow.",
    },
    {
      img_url: jerry,
      name: "Ava Moore",
      message: "I really enjoyed the session.",
    },
    {
      img_url: jerry,
      name: "Isabella Perez",
      message: "Can you send me the files?",
    },
    {
      img_url: jerry,
      name: "Mason Walker",
      message: "Thanks for the update!",
    },
    {
      img_url: jerry,
      name: "Charlotte King",
      message: "It was a productive day!",
    },
    {
      img_url: jerry,
      name: "Lucas Scott",
      message: "Let’s discuss it tomorrow.",
    },
    {
      img_url: jerry,
      name: "Harper Young",
      message: "Thanks for reaching out!",
    },
    {
      img_url: jerry,
      name: "Ethan Adams",
      message: "Looking forward to the meeting.",
    },
    {
      img_url: jerry,
      name: "Mia Nelson",
      message: "Please send the document soon.",
    },
    {
      img_url: jerry,
      name: "Aiden Carter",
      message: "I’m available next week.",
    },
    {
      img_url: jerry,
      name: "Abigail Turner",
      message: "Let’s catch up over coffee.",
    },
  ],
};

const ChatUI = () => {
  const [activeTab, setActiveTab] = useState("friends");
  const [data, setData] = useState(userLists["friends"]);
  const [activeUser, setActiveUser] = useState(data[0]);
  const [message, setMessage] = useState("");
  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const [showChatbox, setShowChatbox] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setShowLeftPanel(true);
        setShowChatbox(false);
      } else {
        setShowLeftPanel(true);
        setShowChatbox(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = () => {
    console.log(message);
    setMessage("");
  };

  const handleTabClick = (category) => {
    if (activeTab !== category) {
      setActiveTab(category);
      setData(userLists[category]);
    }
  };

  const handleUserClick = (user) => {
    setActiveUser(user);
    if (window.innerWidth <= 767) {
      setShowChatbox(true);
      setShowLeftPanel(false);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">View Profile</Menu.Item>
      <Menu.Item key="2">Mute Chat</Menu.Item>
      <Menu.Item key="3">Report User</Menu.Item>
      <Menu.Item key="4">Block User</Menu.Item>
      <Menu.Item key="5">Clear Chat</Menu.Item>
    </Menu>
  );

  return (
    <section className="content-body py-2">
      <div className="chat-area">
        <div className="row">
          <div
            className={`col-12 col-md-5 col-lg-4 col-xl-3 left-side ${
              showLeftPanel ? "d-block" : "d-none"
            }`}
          >
            <div className="chat-header">
              <div className="search-con input-group rounded-pill">
                <div className="input-group-prepend border-0">
                  <button
                    id="button-addon4"
                    type="button"
                    className="btn btn-link text-info"
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} inverse />
                  </button>
                </div>
                <input
                  type="search"
                  placeholder="Search"
                  aria-describedby="button-addon4"
                  className="form-control border-0"
                />
              </div>

              <div className="wrapper">
                <div className={`tab-switch ${activeTab}`}>
                  <div
                    className={`tab ${activeTab === "friends" ? "active" : ""}`}
                    onClick={() => handleTabClick("friends")}
                  >
                    Friends
                  </div>
                  <div
                    className={`tab ${
                      activeTab === "followers" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("followers")}
                  >
                    Followers
                  </div>
                </div>
              </div>
            </div>
            <div className="user-lists">
              {data.map((user, index) => (
                <div
                  key={index}
                  className={`d-flex align-items-center list ${
                    index === 0 ? "active" : ""
                  }`}
                  onClick={() => handleUserClick(user)}
                >
                  <div className="flex-shrink-0">
                    <img
                      className="user-img"
                      src={user.img_url}
                      alt={`${user.name} img`}
                    />
                    <span className={index === 0 ? "active" : ""}></span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5>{user.name}</h5>
                    <p className="last-msg">{user.message}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`col-12 col-md-7 col-lg-8 col-xl-9 ${
              showChatbox ? "d-block" : "d-none"
            } chatbox`}
          >
            <div className="chatbox">
              <div className="chat-header">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    {showChatbox && (
                      <Button
                        onClick={() => {
                          setShowChatbox(false);
                          setShowLeftPanel(true);
                        }}
                        id="close-chat-btn"
                        type="button"
                        className="btn btn-link text-info"
                      >
                        <FontAwesomeIcon icon={faChevronLeft} inverse />
                      </Button>
                    )}
                    <img
                      className="user-img"
                      src={activeUser.img_url}
                      alt={`${activeUser.name} img`}
                    />
                    <span>{activeUser.name}</span>
                  </div>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <button
                      id="button-menu"
                      type="button"
                      className="btn btn-link text-info"
                    >
                      <FontAwesomeIcon icon={faEllipsis} inverse size="xl" />
                    </button>
                  </Dropdown>
                </div>
              </div>
              <div class="msg-body">
                <ul>
                  <li class="sender">
                    <p> Hey, Are you there? </p>
                    <span class="time">10:06 am</span>
                  </li>
                  <li class="sender">
                    <p> Hey, Are you there? </p>
                    <span class="time">10:16 am</span>
                  </li>
                  <li class="repaly">
                    <p>yes!</p>
                    <span class="time">10:20 am</span>
                  </li>
                  <li class="sender">
                    <p> Hey, Are you there? </p>
                    <span class="time">10:26 am</span>
                  </li>
                  <li class="sender">
                    <p> Hey, Are you there? </p>
                    <span class="time">10:32 am</span>
                  </li>
                  <li class="repaly">
                    <p>How are you?</p>
                    <span class="time">10:35 am</span>
                  </li>
                  <li>
                    <div class="line-with-text">
                      <span>Today</span>
                    </div>
                  </li>

                  <li class="repaly">
                    <p> yes, tell me</p>
                    <span class="time">10:36 am</span>
                  </li>
                  <li class="repaly">
                    <p>yes... on it</p>
                    <span class="time">junt now</span>
                  </li>
                </ul>
              </div>
              <div className="message-input">
                <Row gutter={[8, 8]} align="middle">
                  <Col>
                    <Button icon={<FontAwesomeIcon icon={faPaperclip} />} />
                  </Col>
                  <Col flex="auto">
                    <TextArea
                      rows={2}
                      value={message}
                      onChange={handleChange}
                      placeholder="Type your message..."
                      autoSize={{ minRows: 1, maxRows: 3 }}
                    />
                  </Col>
                  <Col>
                    <Button
                      icon={<FontAwesomeIcon icon={faPaperPlane} inverse />}
                      type="primary"
                      onClick={handleSend}
                      disabled={!message.trim()}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatUI;
