import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faRightFromBracket,
  faRightToBracket,
  faUserPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { useGetSearchResultsQuery } from "api/homeApi";
import Login from "components/Login";
import Register from "components/Register";
import ForgotPassword from "components/ForgotPassword";
import DashboardIcon from "assets/images/icons/dashboard.png";
import { useLogOutMutation } from "api/authApi";
import { clearAuth } from "slices/authSlice";
import { setLoginModalVisibility } from "slices/globalSlice";
import { toast } from "react-toastify";
import Logo from "assets/images/logo-1.png";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showDesktopSearchDropdown, setShowDesktopSearchDropdown] =
    useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const { data: searchResults = [] } = useGetSearchResultsQuery(query, {
    skip: !query,
  });
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { showGlobalLoginModal, notificationCount } = useSelector(
    (state) => state.global
  );
  const [logout] = useLogOutMutation();
  const menuRef = useRef(null);
  const profileRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    setShowDesktopSearchDropdown(true);
  }, [searchResults]);

  const handleClickOutside = (event) => {
    if (
      profileRef.current &&
      !profileRef.current.contains(event.target) &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }

    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowDesktopSearchDropdown(false);
      setShowDesktopSearchDropdown(false);
      setQuery("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setQuery(searchTerm);
  };

  const handleModalShow = (modalType) => {
    setShowLoginModal(modalType === "login");
    if (showGlobalLoginModal)
      dispatch(setLoginModalVisibility(modalType === "login"));
    setShowRegisterModal(modalType === "register");
    setShowForgotPasswordModal(modalType === "forgot-password");
  };

  const handleModalHide = (modalType) => {
    switch (modalType) {
      case "login":
        setShowLoginModal(false);
        if (showGlobalLoginModal) dispatch(setLoginModalVisibility(false));
        break;
      case "register":
        setShowRegisterModal(false);
        break;
      case "forgot-password":
        setShowForgotPasswordModal(false);
        break;
      default:
        break;
    }
  };

  const handleSignOut = async () => {
    try {
      // await logout().unwrap();
      dispatch(clearAuth());
      window.localStorage.removeItem("access_token");
      toast.success("Logged out successfully.", { position: "bottom-right" });
      navigate("/");
    } catch (err) {
      toast.error("Logout failed. Please try again.", {
        position: "bottom-right",
      });
    }
    setIsOpen(false);
  };

  const handleSearchClick = () => {
    setIsVisible(true);
    setOverlayVisible(true);
  };

  const handleCloseSearch = () => {
    setIsVisible(false);
    setOverlayVisible(false);
  };

  const handleSearchItemClick = (id) => {
    setShowDesktopSearchDropdown(false);
    setQuery("");
    navigate(`/profile/${id}`);
  };

  return (
    <div className="custom-navbar">
      <div className="brand-con">
        <img
          className="brand-logo"
          src={Logo}
          alt="logo"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="search-con input-group rounded-pill" ref={searchRef}>
        <div className="input-group-prepend border-0">
          <button
            id="button-addon4"
            type="button"
            className="btn btn-link text-info"
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} inverse />
          </button>
        </div>
        <input
          type="search"
          placeholder="Search"
          value={query}
          onChange={handleInputChange}
          aria-describedby="button-addon4"
          className="form-control border-0"
        />
        {showDesktopSearchDropdown && searchResults.length > 0 && (
          <ul className="dropdown">
            {searchResults.map((item, index) => (
              <li key={index} onClick={() => handleSearchItemClick(item.id)}>
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="buttons">
        {isAuthenticated ? (
          <>
            <FontAwesomeIcon
              className="search-icon"
              icon={faSearch}
              size="xl"
              inverse
              onClick={handleSearchClick}
            />
            <div className="notification-bell">
              <FontAwesomeIcon
                className="icon"
                icon={faBell}
                size="xl"
                inverse
                onClick={() => navigate("/notifications")}
                flip="horizontal"
              />
              <span
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "-10px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  lineHeight: "1",
                }}
              >
                {notificationCount}
              </span>
            </div>
            <div
              className="profile"
              ref={profileRef}
              onClick={() => setIsOpen(!isOpen)}
            >
              {user.profile_picture_url ? (
                <div className="img-box">
                  <img src={user.profile_picture_url} alt="Profile" />
                </div>
              ) : (
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleUser}
                  size="lg"
                  inverse
                />
              )}
              <div
                className={isOpen ? "profile-menu active" : "profile-menu"}
                ref={menuRef}
              >
                <ul>
                  <li onClick={() => navigate(`/profile/${user.id}`)}>
                    <FontAwesomeIcon
                      className="icon"
                      icon={faCircleUser}
                      size="lg"
                      inverse
                    />
                    <span className="text-left">
                      {user.first_name + " " + user.last_name}
                    </span>
                  </li>
                  <li onClick={() => navigate("/dashboard/profile")}>
                    <img
                      src={DashboardIcon}
                      className="icon"
                      alt="dashboard-icon"
                    />
                    <span>Dashboard</span>
                  </li>
                  <li onClick={handleSignOut}>
                    <FontAwesomeIcon
                      className="icon"
                      icon={faRightFromBracket}
                      size="lg"
                      inverse
                    />
                    <span>Sign out</span>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="desktop-buttons">
              <button
                type="button"
                className="btn login-btn"
                onClick={() => setShowLoginModal(true)}
              >
                Login
              </button>
              <button
                type="button"
                className="btn register-btn"
                onClick={() => setShowRegisterModal(true)}
              >
                Register
              </button>
            </div>
            <div className="mobile-buttons">
              <FontAwesomeIcon
                className="icon"
                icon={faSearch}
                size="lg"
                inverse
                onClick={handleSearchClick}
              />
              <FontAwesomeIcon
                className="icon"
                icon={faRightToBracket}
                size="lg"
                inverse
                onClick={() => setShowLoginModal(true)}
              />
              <FontAwesomeIcon
                className="icon"
                icon={faUserPlus}
                size="lg"
                inverse
                onClick={() => setShowRegisterModal(true)}
              />
            </div>
          </>
        )}
      </div>
      <Login
        show={showLoginModal || showGlobalLoginModal}
        handleModalHide={handleModalHide}
        handleModalShow={handleModalShow}
      />
      <Register
        show={showRegisterModal}
        setShow={setShowRegisterModal}
        handleModalShow={handleModalShow}
      />
      <ForgotPassword
        show={showForgotPasswordModal}
        setShow={setShowForgotPasswordModal}
        handleModalShow={handleModalShow}
      />
      {isOverlayVisible && (
        <div className="overlay" onClick={handleCloseSearch}></div>
      )}
      {isVisible && (
        <div className="mob-search-con input-group rounded-pill">
          <div className="input-group-prepend border-0">
            <button
              id="button-addon4"
              type="button"
              className="btn btn-link text-info"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} inverse />
            </button>
          </div>
          <input
            type="search"
            placeholder="Search"
            value={query}
            onChange={handleInputChange}
            aria-describedby="button-addon4"
            className="form-control border-0"
          />
          {searchResults.length > 0 && (
            <ul className="dropdown">
              {searchResults.map((item, index) => (
                <li key={index}>{item.name}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
