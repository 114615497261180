import React from "react";
import moment from "moment";
import "./styles.scss";

const AppointmentCard = ({ item }) => {
  return (
    <div className="class-card card shadow-sm">
      <div className="lines"></div>
      <div className="card-body">
        <div className="co-host-images">
          <img
            className="co-host-image"
            src={item.host.profile_picture_url}
            alt="Host"
          />
          {item.co_hosts.map((cohost, index) => (
            <img
              key={index}
              className="co-host-image"
              src={cohost.profile_picture_url}
              alt="Co host"
            />
          ))}
        </div>
        <div className="card-content">
          <div className="card-details">
            <h5 className="class-title mb-1">{item.title}</h5>
            <div className="card-time">
              <small>
                {moment(item.session_date).format("dddd, DD MMM YYYY")}
                <br />
                {moment(item.session_date + " " + item.start_time).format(
                  "hh:mm A"
                )}{" "}
                -{" "}
                {moment(item.session_date + " " + item.end_time).format(
                  "hh:mm A"
                )}
              </small>
            </div>
            <p className="instructor-name mb-0 text-muted">
              {item.description}
            </p>
            <p className="instructor-name mb-0 text-muted">$ {item.price}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
