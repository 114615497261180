import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Loader = ({ isLoading, message }) => {
  return (
    isLoading && (
      <div className="loading-container">
        <div className="loading-inner">
          <FontAwesomeIcon icon={faSpinner} className="spinner" />
          <p className="message">{message}</p>
        </div>
      </div>
    )
  );
};

export default Loader;
