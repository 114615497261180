import React, { useState } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import "./styles.scss";

const BidModal = ({ show, handleClose }) => {
  const [timeSlot, setTimeSlot] = useState("");
  const [message, setMessage] = useState("");
  const [offer, setOffer] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Selected Time Slot:", timeSlot);
    console.log("Message:", message);
    console.log("Offer:", offer);
    toast.success("Successfully send your bid details to host", {
      position: "bottom-right",
    });
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} className="bid-modal" centered>
      <Modal.Body>
        <h1 className="title">Book Session</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="offer" className="mt-3">
            <Form.Label>Your Offer</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                min="0"
                step="0.01"
                value={offer}
                onChange={(e) => setOffer(e.target.value)}
                placeholder="Enter your offer"
                required
                className="custom-input"
              />
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="message" className="mt-3">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Write message"
              className="custom-input"
            />
          </Form.Group>

          <button type="submit" className="bid-submit-btn">
            Submit
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BidModal;
