import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
  name: "global",
  initialState: {
    showGlobalLoginModal: false,
    notificationCount: 0,
  },
  reducers: {
    setLoginModalVisibility: (state, action) => {
      state.showGlobalLoginModal = action.payload;
    },
    setNotificationsCount(state, action) {
      state.notificationCount = action.payload;
    },
  },
});

export const { setLoginModalVisibility, setNotificationsCount } =
  globalSlice.actions;
export default globalSlice.reducer;
