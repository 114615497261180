import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const homeApi = createApi({
  reducerPath: "homeApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHomeData: builder.query({
      query: () => ({ url: "/home" }),
    }),
    getSearchResults: builder.query({
      query: (searchTerm) => ({
        url: `/search`,
        params: { q: searchTerm },
      }),
    }),
    getUserProfile: builder.query({
      query: (profileId) => ({ url: `/user_profile?id=${profileId}` }),
    }),
    sendRequest: builder.mutation({
      query: (friendId) => ({
        url: "/friends",
        method: "POST",
        body: { friend_id: friendId },
      }),
    }),
    acceptRequest: builder.mutation({
      query: (friendId) => ({
        url: `/friends/${friendId}/accept`,
        method: "POST",
      }),
    }),
    rejectRequest: builder.mutation({
      query: (friendId) => ({
        url: `/friends/${friendId}/areject`,
        method: "POST",
      }),
    }),
    removeFriend: builder.mutation({
      query: (friendId) => ({
        url: `/friends/${friendId}`,
        method: "DELETE",
      }),
    }),
    followUser: builder.mutation({
      query: (userId) => ({
        url: `/follows`,
        method: "POST",
        body: { follower_id: userId },
      }),
    }),
    unfollowUser: builder.mutation({
      query: (userId) => ({
        url: `/follows/${userId}`,
        method: "DELETE",
      }),
    }),
    fetchHosts: builder.query({
      query: () => "hosts",
    }),
    fetchBrowseUsers: builder.query({
      query: ({ type, value }) => ({
        url: "browse",
        method: "POST",
        body: { type, value },
      }),
    }),
  }),
});

export const {
  useGetHomeDataQuery,
  useGetSearchResultsQuery,
  useGetUserProfileQuery,
  useSendRequestMutation,
  useAcceptRequestMutation,
  useRejectRequestMutation,
  useRemoveFriendMutation,
  useFollowUserMutation,
  useUnfollowUserMutation,
  useFetchHostsQuery,
  useFetchBrowseUsersQuery,
} = homeApi;
