import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import UserTile from "components/UserTile";
import AppointmentCard from "components/AppointmentCard";
import Loader from "components/Loader";
import { useGetFollowingsAndAppointmentsQuery } from "api/profileApi";
import "./styles.scss";

const Following = () => {
  const { user } = useSelector((state) => state.auth);
  const { data, error, isLoading, refetch } =
    useGetFollowingsAndAppointmentsQuery(user.id);

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return <Loader isLoading />;
  }

  if (error) {
    return (
      <div className="error-message">
        <p>Failed to load data. Please try again later.</p>
      </div>
    );
  }

  const { followings = [], appointments = [] } = data || {};

  return (
    <div className="following-feeds-page content-body">
      <div className="appointments-list mb-3">
        <div className="header">
          <h1 className="section-title">Upcoming Appointments</h1>
        </div>
        <div className="content">
          {appointments.length === 0 ? (
            <p className="text-white">No upcoming appointments.</p>
          ) : (
            appointments.map((appointment, index) => (
              <AppointmentCard key={index} item={appointment} />
            ))
          )}
        </div>
      </div>

      <div className="following-list mb-2">
        <div className="header">
          <h1 className="section-title">Following</h1>
        </div>
        <div className="content">
          {followings.length === 0 ? (
            <p className="text-white">No followings to display.</p>
          ) : (
            followings.map((following, index) => (
              <UserTile key={index} item={following} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Following;
