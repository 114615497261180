import React from "react";
import { useParams } from "react-router-dom";
import StripeImage from "assets/images/connect-stripe-logo.png";
import "./styles.scss";
import { useGetConnectAccountLinkQuery } from "api/stripeApi";

const ConnectLink = () => {
  const { account_id } = useParams();
  const { data } = useGetConnectAccountLinkQuery(account_id);
  const handleRedirect = () => {
    window.location.replace(data.url);
  };

  return (
    <div className="container-connect">
      <img src={StripeImage} alt="Stripe logo" className="stripe_logo" />
      <h1 className="stripe-text">
        You need to register yourself to stripe connect in order to log into
        your account later on.
      </h1>
      <button className="stripe_button" onClick={handleRedirect}>
        Redirect To Stripe
      </button>
    </div>
  );
};

export default ConnectLink;
