import React from "react";
import ProfileHeader from "./ProfileHeader";
import ProfileForm from "./ProfileForm";
import { useSelector } from "react-redux";
import { useGetProfileByIdQuery } from "api/profileApi";
import Loader from "components/Loader";

const UserProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    data,
    error,
    isLoading,
    refetch: refetchUser,
  } = useGetProfileByIdQuery(user.id);

  return isLoading ? (
    <Loader isLoading={isLoading} />
  ) : (
    <>
      <ProfileHeader profileData={data.user} />
      <ProfileForm profileData={data.user} refetchUser={refetchUser} />
    </>
  );
};

export default UserProfile;
