import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Select, DatePicker, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useUpdateProfileMutation } from "api/profileApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "components/Loader";
import dayjs from "dayjs";
import "./styles.scss";
import { toast } from "react-toastify";

const { Option } = Select;

const ProfileForm = ({ profileData, refetchUser }) => {
  const [form] = Form.useForm();
  const [bio, setBio] = useState(profileData.bio || "");
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [clips, setClips] = useState([]);
  const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();
  const scrollableRef = useRef();

  useEffect(() => {
    form.setFieldsValue({
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      date_of_birth: profileData.date_of_birth
        ? dayjs(profileData.date_of_birth, "YYYY-MM-DD")
        : null,
      phone_number: profileData.phone_number,
      country: profileData.country,
      city: profileData.city,
      facebook_url: profileData.facebook_url,
      instagram_url: profileData.instagram_url,
      tiktok_url: profileData.tiktok_url,
      twitter_url: profileData.twitter_url,
      linkedin_url: profileData.linkedin_url,
      youtube_url: profileData.youtube_url,
      streaming_platforms: profileData.streaming_platforms || [],
      game_platforms: profileData.game_platforms || [],
      games: profileData.games || [],
      bio: profileData.bio || "",
    });
    if (profileData.photos && profileData.photos.length > 0) {
      const initialFileList = profileData.photos.map((photo, index) => ({
        uid: photo.id,
        name: `Photo_${index}`,
        url: photo.url,
        status: "done",
      }));
      setPhotos(initialFileList);
    }
    if (profileData.clips && profileData.clips.length > 0) {
      const initialFileList = profileData.clips.map((clip, index) => ({
        uid: clip.id,
        name: `Clip_${index}`,
        url: clip.url,
        status: "done",
      }));
      setClips(initialFileList);
    }
  }, [form, profileData]);

  const handleProfileImageChange = (file) => {
    setProfileImage(file);
    return false;
  };

  const handleCoverImageChange = (file) => {
    setCoverImage(file);
    return false;
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    if (profileImage) {
      formData.append("profile_picture", profileImage);
    }
    if (coverImage) {
      formData.append("cover_photo", coverImage);
    }
    if (bio) {
      formData.append("bio", bio);
    }

    photos.forEach((photo, index) => {
      formData.append(`photo_${index}`, photo.originFileObj || photo.uid);
    });

    clips.forEach((clip, index) => {
      formData.append(`clip_${index}`, clip.originFileObj || clip.uid);
    });

    try {
      const response = await updateProfile({
        id: profileData.id,
        formData,
      }).unwrap();
      if (scrollableRef.current) {
        scrollableRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
      toast.success("Profile updated successfully", {
        position: "bottom-right",
      });
      refetchUser();
    } catch (err) {
      console.error("Error updating profile:", err);
    }
  };

  const handlePhotosChange = (info) => {
    let updatedFileList = info.fileList || [];
    setPhotos(updatedFileList);
    return false;
  };

  const handleClipsChange = (info) => {
    let updatedFileList = info.fileList || [];
    setClips(updatedFileList);
    return false;
  };

  if (isUpdating) {
    return <Loader isLoading={isUpdating} />;
  }

  return (
    <div className="profileForm" ref={scrollableRef}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          first_name: "",
          last_name: "",
          // email: "",
          phone_number: "",
          date_of_birth: null,
          educationLevel: "",
          country: "",
          city: "",
          facebook_url: "",
          instagram_url: "",
          tiktok_url: "",
          twitter_url: "",
          linkedin_url: "",
          youtube_url: "",
          streaming_platforms: [],
          game_platforms: [],
          games: [],
        }}
      >
        <div className="row">
          <div className="col-sm-6">
            <Form.Item label="Profile Image" name="profileImage">
              <Upload
                name="profileImage"
                accept="image/*"
                showUploadList={false}
                beforeUpload={handleProfileImageChange}
              >
                <Button icon={<UploadOutlined />}>Select Profile Image</Button>
              </Upload>
              {profileImage && (
                <img
                  src={URL.createObjectURL(profileImage)}
                  alt="profile"
                  style={{ width: 100, marginTop: 10 }}
                />
              )}
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="Cover Image" name="coverImage">
              <Upload
                name="coverImage"
                accept="image/*"
                showUploadList={false}
                beforeUpload={handleCoverImageChange}
              >
                <Button icon={<UploadOutlined />}>Select Cover Image</Button>
              </Upload>
              {coverImage && (
                <img
                  src={URL.createObjectURL(coverImage)}
                  alt="profile"
                  style={{ width: 100, marginTop: 10 }}
                />
              )}
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name!",
                },
              ]}
            >
              <Input placeholder="Enter your first name" />
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                { required: true, message: "Please enter your last name!" },
              ]}
            >
              <Input placeholder="Enter your last name" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Form.Item label="Date of Birth" name="date_of_birth">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item
              label="Phone Number"
              name="phone_number"
              rules={[
                { required: true, message: "Please enter your phone number!" },
              ]}
            >
              <Input placeholder="Enter your phone number" />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Form.Item label="Country" name="country">
              <Input placeholder="Enter your country" />
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="City" name="city">
              <Input placeholder="Enter your city" />
            </Form.Item>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter your email!" },
                { type: "email", message: "Please enter a valid email!" },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
          </div>
        </div> */}
        <div className="row">
          <Form.Item label="Bio" name="bio">
            <ReactQuill
              value={bio}
              onChange={setBio}
              placeholder="Enter a short bio"
            />
          </Form.Item>
        </div>
        <h4 className="text-white">Social Handles</h4>
        <div className="row">
          <div className="col-sm-6">
            <Form.Item label="Facebook" name="facebook_url">
              <Input placeholder="Enter Facebook URL" />
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="Instagram" name="instagram_url">
              <Input placeholder="Enter Instagram URL" />
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="Tiktok" name="tiktok_url">
              <Input placeholder="Enter Tiktok URL" />
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="Twitter" name="twitter_url">
              <Input placeholder="Enter Twitter URL" />
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="LinkedIn" name="linkedin_url">
              <Input placeholder="Enter LinkedIn URL" />
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="Youtube" name="youtube_url">
              <Input placeholder="Enter Youtube URL" />
            </Form.Item>
          </div>
        </div>

        <h4 className="text-white">Additional Information</h4>
        <div className="row">
          <div className="col-sm-6">
            <Form.Item
              label="Select Streaming Platform"
              name="streaming_platforms"
            >
              <Select
                mode="multiple"
                placeholder="Select your streaming platform"
              >
                <Option value="kick">Kick</Option>
                <Option value="twitch">Twitch</Option>
                <Option value="rumble">Rumble</Option>
                <Option value="youtube">YouTube</Option>
                <Option value="facebook-live">Facebook Live</Option>
                <Option value="instagram-live">Instagram Live</Option>
                <Option value="trovo">Trovo</Option>
                <Option value="dlive">DLive</Option>
                <Option value="vimeo">Vimeo</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="Select Platform" name="game_platforms">
              <Select mode="multiple" placeholder="Select your gaming platform">
                <Option value="xbox">Xbox</Option>
                <Option value="pc">PC</Option>
                <Option value="playstation">PlayStation</Option>
                <Option value="nintendo-switch">Nintendo Switch</Option>
                <Option value="mobile">Mobile</Option>
                <Option value="stadia">Google Stadia</Option>
                <Option value="vr">Virtual Reality</Option>
                <Option value="oculus">Oculus</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="Select Games" name="games">
              <Select mode="multiple" placeholder="Select games you play">
                <Option value="fortnite">Fortnite</Option>
                <Option value="minecraft">Minecraft</Option>
                <Option value="valorant">Valorant</Option>
                <Option value="league-of-legends">League of Legends</Option>
                <Option value="call-of-duty">Call of Duty</Option>
                <Option value="apex-legends">Apex Legends</Option>
                <Option value="pubg">PUBG</Option>
                <Option value="fifa">FIFA</Option>
                <Option value="overwatch">Overwatch</Option>
                <Option value="gta-v">GTA V</Option>
                <Option value="mobile-legends">Mobile Legends</Option>
                <Option value="clash-royale">Clash Royale</Option>
                <Option value="among-us">Among Us</Option>
                <Option value="pokemon-go">Pokemon GO</Option>
                <Option value="the-witcher-3">The Witcher 3</Option>
                <Option value="cyberpunk-2077">Cyberpunk 2077</Option>
                <Option value="assassins-creed">Assassin’s Creed</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <h4 className="text-white">Photos and Clips</h4>
        <div className="row">
          <div className="col-sm-6">
            <Form.Item label="Upload Photos" name="photos">
              <Upload
                multiple
                accept="image/*"
                fileList={photos}
                onChange={handlePhotosChange}
                beforeUpload={() => false}
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Upload Photos</Button>
              </Upload>
            </Form.Item>
          </div>
          <div className="col-sm-6">
            <Form.Item label="Upload Clips" name="clips">
              <Upload
                multiple
                accept="video/*"
                fileList={clips}
                onChange={handleClipsChange}
                beforeUpload={() => false}
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Upload Clips</Button>
              </Upload>
            </Form.Item>
          </div>
        </div>

        <div className="submit-btn">
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {isUpdating ? "Saving..." : "Save"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ProfileForm;
