import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SessionForm from "../Form";
import {
  useGetSessionsQuery,
  useDeleteSessionMutation,
} from "../../../api/sessionApi";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import "./styles.scss";

const SessionsListing = () => {
  const {
    data: sessions,
    error,
    isLoading,
    refetch: refetchSessions,
  } = useGetSessionsQuery();
  const [deleteSession, { isDeleting, isDeletingError }] =
    useDeleteSessionMutation();
  const [selectedSession, setSelectedSession] = useState(null);
  const [showSessionModal, setShowSessionModal] = useState(false);

  const handleEdit = (session) => {
    let updatedSession = { ...session };
    updatedSession.co_hosts = session.co_hosts.map((item) => item.id);
    setSelectedSession(updatedSession);
    setShowSessionModal(true);
  };

  const handleDelete = (id) => {
    try {
      deleteSession(id);
      toast.success("Successfull delete the session", {
        position: "bottom-right",
      });
      refetchSessions();
    } catch (error) {
      toast.error("Something went wrong. Try Again", {
        position: "bottom-right",
      });
      console.log(error);
    }
  };

  return (
    <div className="sessions-container">
      <div className="header">
        <h2>Appointments</h2>
        <div className="actions">
          <button className="btn" onClick={() => setShowSessionModal(true)}>
            <FontAwesomeIcon icon={faPlus} size="lg" inverse />
          </button>
        </div>
      </div>
      <table className="table table-dark table-hover">
        <thead>
          <tr>
            <th>Co Hosts</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Price</th>
            <th>Slots</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <Loader isLoading={isLoading || isDeleting} />
          {sessions?.length > 0 ? (
            sessions.map((session, index) => (
              <tr key={index}>
                <td data-label="Co Hosts">
                  <div className="co-host-images">
                    {session.co_hosts.map((cohost, index) => (
                      <img
                        key={index}
                        className="co-host-image"
                        src={cohost.profile_picture_url}
                        alt="Co host"
                      />
                    ))}
                  </div>
                </td>
                <td data-label="Date">
                  {moment(session.session_date).format("dddd, DD MMM YYYY")}
                </td>
                <td data-label="Start Time">
                  {moment(
                    session.session_date + " " + session.start_time
                  ).format("hh:mm A")}
                </td>
                <td data-label="End Time">
                  {moment(session.session_date + " " + session.end_time).format(
                    "hh:mm A"
                  )}
                </td>
                <td data-label="Price">{session.price}</td>
                <td data-label="Slots">
                  {session.reserved_slots + " / " + session.max_users}
                </td>
                <td data-label="Actions">
                  <div className="action-buttons">
                    <button className="btn" onClick={() => handleEdit(session)}>
                      <FontAwesomeIcon icon={faPenToSquare} size="lg" inverse />
                    </button>
                    <button
                      className="btn"
                      onClick={() => handleDelete(session.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} size="lg" inverse />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7} className="text-center">
                No sessions available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {sessions?.length > 0 && sessions?.length > 10 && (
        <div className="pagination">
          <span>Showing data 1 to 8 of 10 entries</span>
          <div className="page-controls">
            <button className="btn btn-sm btn-secondary">{"<"}</button>
            <button className="btn btn-sm btn-primary">1</button>
            <button className="btn btn-sm btn-secondary">2</button>
            <button className="btn btn-sm btn-secondary">3</button>
            <button className="btn btn-sm btn-secondary">...</button>
            <button className="btn btn-sm btn-secondary">40</button>
            <button className="btn btn-sm btn-secondary">{">"}</button>
          </div>
        </div>
      )}
      <SessionForm
        show={showSessionModal}
        handleClose={() => setShowSessionModal(false)}
        refetchSessions={refetchSessions}
        selectedSession={selectedSession}
      />
    </div>
  );
};

export default SessionsListing;
