import React from "react";
import "./styles.scss";

const ClipsGallery = ({ clips }) => {
  return (
    <div className="clips-gallery">
      {clips.length === 0 ? (
        <p>No clips available</p>
      ) : (
        clips.map((clip, index) => (
          <div key={index} className="clip-container">
            <video
              controls
              className="clip-player"
              src={clip}
              alt={`Clip ${index + 1}`}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        ))
      )}
    </div>
  );
};

export default ClipsGallery;
