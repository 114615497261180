import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const stripeApi = createApi({
  reducerPath: "stripeApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = window.localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Stripe"],
  endpoints: (builder) => ({
    getConnectAccountLink: builder.query({
      query: (payload) => ({
        url: `/stripe/account/link/${payload}`,
        method: "GET",
      }),
      invalidatesTags: ["Stripe"],
    }),
    updateUserConnectAccount: builder.mutation({
      query: (payload) => ({
        url: `/stripe/connect/${payload.account_id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Stripe"],
    }),
    createDashboardLink: builder.mutation({
      query: (payload) => ({
        url: `/stripe/dashboard/link`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Stripe"],
    }),
  }),
});

export const {
  useGetConnectAccountLinkQuery,
  useUpdateUserConnectAccountMutation,
  useCreateDashboardLinkMutation,
} = stripeApi;
