import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StripeImage from "assets/images/connect-stripe-logo.png";
import "./styles.scss";
import { useUpdateUserConnectAccountMutation } from "api/stripeApi";

const ConnectSuccess = () => {
  const { account_id } = useParams();
  const [updateUserConnectAccount] = useUpdateUserConnectAccountMutation();
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/");
  };

  const updateUser = async () => {
    await updateUserConnectAccount({ account_id });
  };

  useEffect(() => {
    updateUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container-connect">
      <img src={StripeImage} alt="Stripe logo" className="stripe_logo" />
      <h1 className="stripe-text">
        Congratulations You have successfully connected your connect account.
      </h1>
      <button className="stripe_button" onClick={handleRedirect}>
        View Site
      </button>
    </div>
  );
};

export default ConnectSuccess;
